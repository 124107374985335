import { render, staticRenderFns } from "./index.vue?vue&type=template&id=8c3fc7aa&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=8c3fc7aa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c3fc7aa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoSignupForm: require('/var/www/udimi-front-outside/releases/738/components/common/CoSignupForm.vue').default,CoVideoPlayer: require('/var/www/udimi-front-outside/releases/738/components/common/CoVideoPlayer.vue').default,IndLiveReviews: require('/var/www/udimi-front-outside/releases/738/components/index/IndLiveReviews.vue').default,IndReviews: require('/var/www/udimi-front-outside/releases/738/components/index/IndReviews.vue').default,IndAward: require('/var/www/udimi-front-outside/releases/738/components/index/IndAward.vue').default,IndSoloAds: require('/var/www/udimi-front-outside/releases/738/components/index/IndSoloAds.vue').default,IndIdVerifiedSellers: require('/var/www/udimi-front-outside/releases/738/components/index/IndIdVerifiedSellers.vue').default,IndTopSellers: require('/var/www/udimi-front-outside/releases/738/components/index/IndTopSellers.vue').default,IndPersonalTouch: require('/var/www/udimi-front-outside/releases/738/components/index/IndPersonalTouch.vue').default,IndWhatMakesUdimiSoloAdsBetter: require('/var/www/udimi-front-outside/releases/738/components/index/IndWhatMakesUdimiSoloAdsBetter.vue').default,IndSafety: require('/var/www/udimi-front-outside/releases/738/components/index/IndSafety.vue').default,IndBlocks: require('/var/www/udimi-front-outside/releases/738/components/index/IndBlocks.vue').default,IndAffiliatesMakeMoreMoney: require('/var/www/udimi-front-outside/releases/738/components/index/IndAffiliatesMakeMoreMoney.vue').default,IndApplication: require('/var/www/udimi-front-outside/releases/738/components/index/IndApplication.vue').default,CoAppDialog: require('/var/www/udimi-front-outside/releases/738/components/common/CoAppDialog.vue').default,CoGreyBox: require('/var/www/udimi-front-outside/releases/738/components/common/CoGreyBox.vue').default,CoConsentUseSite: require('/var/www/udimi-front-outside/releases/738/components/common/CoConsentUseSite.vue').default})
